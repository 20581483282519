.copy-right {
    font-size: 14px;
    color:
        #8B8B8B;
}

.footer_org {
    background-color: #051b13;
    padding: 10px 0;
}

.footer_t1 {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}

.footer_t2 {
    font-size: 12px;
    color: yellow;
    font-weight: 400;
}


/* banner2 */
.banner{
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;

    width: 100%;

}
.banner-wrapper {
    border-radius: 0 0 8px 8px;
    height: 42rem;
    overflow: hidden;
}

  
  
.hr_padding{
 border: 1px solid #2b6259;
}
/* .home_main{
padding: 20px;
} */
.qr_p1 {
    /* font-size: 18px;
    font-weight: 600; */
    color: #ffc433;
    margin-bottom: 0;
}
.qr_p2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    color: #6A6A6A;
}

.qr_p4{
    margin-bottom: 0;  
    font-size: 20px;
    font-weight: 400;
}
.qr_p5{
    margin-bottom: 0;  
    font-size: 34px;
    font-weight: 600;  
    color: #1E1E1E;
   
}
.qr_p6{
    margin-bottom: 0;  
    font-size: 20px;
    font-weight: 600;  
    color: #6A6A6A;
    margin-top: -5px;
}
.qr_p7{
 font-size: 20px;
 color: red;
 font-weight: 600;
 color: #1E1E1E;
 margin-bottom: 0;
}

.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height to center vertically */
    text-align: center;
    background-color: #f8f9fa; /* Optional background color */
}

.error-message {
    font-size: 1.5rem;
    color: #dc3545; /* Red color for error message */
    font-weight: bold;
}

.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    text-align: center;
}

.spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #2b6259; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-container p {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #333;
}
/* new */
.banner-image{
width: 140px;
height: auto;
}
.head_text{
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    color: #005A2A;
    margin-left: 20px;
    margin-right: 20px;
    text-transform: uppercase;

}
.border_line {
    width: 80%;  /* Adjust the width as needed */
    border-top: 1px solid #D9D9D9;  /* Adjust the thickness and color of the border */
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .text-left {
    text-align: start;
}
.text-right {
    text-align: end;
}
table tbody td{
 color: #6A6A6A!important;
 font-weight: 500;
 font-size: 16px;
}
/* button */
.custom-button {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    background-color: #FFFFFF;
    color: #6A6A6A;
    border-radius: 8px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    gap: 6px;
    transition: background-color 0.3s ease;
    border: 1px solid #969696;
  }
  .download_btn{
    background-color: #005A2A;
    color: #FFFFFF;
  }
  
  .custom-button:hover {
    background-color: #969696;
    color: #FFFFFF;
  }
  
  .button-icon {
    width: 24px;
    height: 24px;
  }
  
  .button-text {
    font-weight: bold;
  }
  .table{
    width: 60%!important;
  }
  @media (max-width: 768px) {
    .sec_3_right{
        padding-left: 0!important;
        padding-right: 0!important;

    }
    .head_text{
        font-size: 27px;
        font-weight: 700;
        line-height: 30px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .qr_p2{
        font-size: 14px;
    }
    .qr_p5{
        line-height: 35px;
        font-size: 26px;
    }
    .qr_p6{
        font-size: 14px;
    }
    .table{
        width: 90%!important;
    }
    .button_row{
        padding-right: 20px;
        padding-left: 20px;
    }
    .custom-button{
        display: block;
    }
    .button-text{
        margin-left: 10px;
    }
    .banner-wrapper{
        height: 30rem;
    }
  }
  
  

